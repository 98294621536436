const isMobile = () => {
    if (typeof window !== 'undefined' && window.navigator) {
        if (window.navigator.userAgent.match(/Android/i)
            || window.navigator.userAgent.match(/webOS/i)
            || window.navigator.userAgent.match(/iPhone/i)
            || window.navigator.userAgent.match(/iPad/i)
            || window.navigator.userAgent.match(/iPod/i)
            || window.navigator.userAgent.match(/BlackBerry/i)
            || window.navigator.userAgent.match(/Windows Phone/i)
            || window.innerWidth <= 490
        ) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export default isMobile;
