import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTrail, animated, useSpring } from 'react-spring';
import { LayoutType } from './Common';

const GradientWrapper = styled(animated.div)`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    transition: all ease 200ms;
`;

const GradientItem = styled(animated.div)`
    background-color: ${props => props.bgcolor};
    width: calc(100% / 9);
    height: 100%;
`;

const gradientColors = [
    'rgb(179, 179, 179)',
    'rgb(158, 158, 158)',
    'rgb(138, 138, 138)',
    'rgb(120, 120, 120)',
    'rgb(99, 99, 99)',
    'rgb(79, 79, 79)',
    'rgb(59, 59, 59)',
    'rgb(41, 41, 41)',
    'rgb(20, 20, 20)',
];

const config1 = {
    mass: 1,
    tension: 5000,
    friction: 400,
    velocity: 1,
    clamp: true,
    // duration: 3000,
};

interface Props {
    layouttype?: LayoutType;
}

export const Gradient: FC<Props & HTMLAttributes<HTMLDivElement>> = (props) => {
    if (props.layouttype === LayoutType.HOME) {
        const trail = useTrail(gradientColors.length, {
            config: config1, // config.default,
            height: '100%',
            from: { height: '0%' },
        });

        return (
            <GradientWrapper {...props}>
                {trail.map(({ ...rest }, index) => (
                    <GradientItem
                        key={gradientColors[index]}
                        bgcolor={gradientColors[index]}
                        style={{ ...rest }}>
                    </GradientItem>
                ))}
            </GradientWrapper>
        );

    }

    const animProps = useSpring({
        // config: config1, // { ...config.stiff},
        from: { opacity: 0, transform: 'translateY(-50px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
    });

    return (
        <GradientWrapper {...props} style={animProps}>
            {gradientColors.map((color, index) => (
                <GradientItem
                    key={index}
                    bgcolor={color}>
                </GradientItem>
            ))}
        </GradientWrapper>
    );
}
