import React, { FC } from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby'
import { PostGridItem } from './';
import { Post, queryPostToPost } from './Common';

const PostGridWrapper = styled.div`
    width: 100%;
`;

const PostGridContent = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;

    .postItem:not(:last-child) {
        margin-bottom: var(--space-l);
    }
`;

export const PostGrid: FC<any> = () => {
    return (
        <StaticQuery query={postGridQuery} render={data => {
            const posts = data.posts.edges.map(queryPostToPost)

            return (
                <PostGridWrapper>
                    <PostGridContent>
                        {
                            posts.map((post: Post) => (
                                <PostGridItem key={post.slug} post={post} />
                            ))
                        }
                    </PostGridContent>
                </PostGridWrapper>
            )
        }}
        />
    );
};


export const postGridQuery = graphql`
query postGridQuery {
    posts: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { category: { ne: null } } }
    ) {
        totalCount
        edges {
            node {
                excerpt(pruneLength: 200, truncate: true)
                fields {
                    slug
                }
                frontmatter {
                    title
                    description
                    date(formatString: "DD.MM.YYYY", locale: "fr-FR")
                    displayDate
                    category
                    tags
                    cover {
                        childImageSharp {
                            fluid(quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    images {
                        title
                    }
                }
            }
        }
    }
}
`;
