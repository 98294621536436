import React from 'react'
import { themes } from '../../config/theme';
import isMobile from '../utils/isMobile';

export interface ThemeState {
    isMobile: boolean;
    dark: boolean;
    toggleDark: () => void;
}

const defaultState: ThemeState = {
    isMobile: false,
    dark: false,
    toggleDark: () => { },
}

export const ThemeContext = React.createContext(defaultState);

// Getting dark mode information from OS!
// You need macOS Mojave + Safari Technology Preview Release 68 to test this currently.
const supportsDarkMode = () =>
    (window.matchMedia('(prefers-color-scheme: dark)').matches === true);

export class ThemeProvider extends React.Component<any, ThemeState> {

    constructor(props: any) {
        super(props);
        this.state = defaultState;
        this.toggleDark = this.toggleDark.bind(this);
    }

    toggleDark() {
        const isDark = !this.state.dark;
        sessionStorage.setItem('theme', isDark ? 'dark' : 'light');
        this.setTheme(isDark);
    }

    componentDidMount() {
        const theme = sessionStorage.getItem('theme');
        const isDark = theme === 'dark';

        if (theme) {
            this.setTheme(isDark);
        } else if (supportsDarkMode()) {
            this.setTheme(true);
        } else {
            this.setTheme(false);
        }
    }

    setTheme(dark: boolean) {
        this.setState({
            isMobile: isMobile(),
            dark
        });

        const currentTheme = themes[dark ? 'dark' : 'light'];

        Object.keys(currentTheme.colors).map((key: string) => {
            const cssKey = `--${key}`;
            const cssValue = currentTheme.colors[key];
            document.documentElement.style.setProperty(cssKey, cssValue);
        });

        const metaThemeColor = document.querySelector("meta[name=theme-color]");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", currentTheme.colors.secondary);
        }

        if (typeof window !== 'undefined') {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
    }

    render() {
        const { children } = this.props
        const { isMobile, dark } = this.state
        const value = {
            isMobile,
            dark,
            toggleDark: this.toggleDark,
        };

        return (
            <ThemeContext.Provider value={value}>
                {children}
            </ThemeContext.Provider>
        );
    }
}
