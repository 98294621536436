import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { ThemeContext } from './ThemeContext';
import { ThemeIcon } from './Icons';
import { mobileBreakPoint } from './Common';

const ThemeSwitcherBtnWrapper = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    user-select: none;
    margin-bottom: -4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:active
    &:focus {
        outline: none;
    }

    svg {
        width: 1.8rem;
        height: 1.8rem;
        margin-bottom: 6px;

        path {
            fill: var(--color);
        }
    }

    @media ${mobileBreakPoint} {
        width: 2rem;
        height: 2rem;

        svg {
            width: 1.8rem;
            height: 1.8rem;
        }
    }
`;

export const ThemeSwitcherBtn: FC<HTMLAttributes<HTMLDivElement>> = (props: any) => {
    return (
        <ThemeContext.Consumer>
            {theme => (
                <ThemeSwitcherBtnWrapper {...props} onClick={(e) => theme.toggleDark()} aria-label="Switch site theme">
                    <ThemeIcon />
                </ThemeSwitcherBtnWrapper>

            )}
        </ThemeContext.Consumer>
    );
}
