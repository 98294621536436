import React, { FC } from 'react';
import styled from 'styled-components';
import { Text, Post, mobileBreakPoint } from './Common';
import { PictureIcon, TagIcon } from './Icons';

const PostInfosWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--space-xs);

    .item:not(:last-child) {
        margin-bottom: var(--space-xs);
    }

    ${Text} {
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    @media ${mobileBreakPoint} {
    }
`;

const DetailRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;

    :first-child {
        margin-right: var(--space-xs);
    }

    ${Text} {
        flex-wrap: wrap;
    }

    svg {
        display: inline-block;
        height: var(--space-m);
        min-height: var(--space-m);
        min-width: var(--space-m);
        margin-right: var(--space-xxs);
    }

    @media ${mobileBreakPoint} {
        svg {
            display: inline-block;
            height: var(--space-s);
            min-height: var(--space-s);
            min-width: var(--space-s);
            margin-right: var(--space-xxs);
        }
    }
`;

const DetailsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-top: 2px solid var(--color);
    border-bottom: 2px solid var(--color);
    padding: var(--space-xxs) 0;
`;

interface Props {
    post: Post;
    onlyTitle?: boolean;
}

export const PostInfos: FC<Props> = ({ post, onlyTitle = false }) => {

    return (
        <PostInfosWrapper>
            {post.title &&
                <Text
                    className="item"
                    fontSize="var(--font-size-l)"
                    fontSizeMobile="calc(var(--font-size-m) + 0.5rem)"
                    whiteSpace="normal">
                    {post.title}
                </Text>
            }

            {!onlyTitle &&
            <>
                <DetailsWrapper className="item">
                    {post.tags &&
                        <DetailRow>
                            <TagIcon />
                            <Text
                                fontSize="var(--font-size-m)"
                                fontSizeMobile="var(--font-size-s)"
                                whiteSpace="normal">
                                {post.tags.join(', ')}
                            </Text>
                        </DetailRow>
                    }

                    {post.imagesCount &&
                        <DetailRow>
                            <PictureIcon />
                            <Text
                                fontSize="var(--font-size-m)"
                                fontSizeMobile="var(--font-size-s)">
                                {post.imagesCount}
                            </Text>
                        </DetailRow>
                    }

                </DetailsWrapper>

                {post.description &&
                    <Text
                        className="item"
                        fontSize="var(--font-size-m)"
                        fontSizeMobile="var(--font-size-s)"
                        whiteSpace="normal">
                        {post.description}
                    </Text>
                }
                </>
            }
        </PostInfosWrapper>
    );
}

