import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { GitHubIcon, MailIcon, PencilIcon } from './Icons';
import siteConfig from '../../config/site';
import { NavLink, mobileBreakPoint } from './Common';
import { ThemeSwitcherBtn } from './ThemeSwitcherBtn';


const SocialBarWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: center;

    @media ${mobileBreakPoint} {
        justify-content: center;
    }
`;

const SocialLink = styled.a.attrs({
    target: "_blank",
    rel: "noopener noreferrer"
})`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;

    &:focus {
        outline: none;
    }

    svg {
        width: 1.5rem;
        height: 1.5rem;

        path {
            fill: var(--color);
        }
    }

    @media ${mobileBreakPoint} {
        width: 2rem;
        height: 2rem;

        svg {
            width: 1.6rem;
            height: 1.6rem;
        }
    }
`;
const SocialSeparator = styled.div`
    width: 6px;
    min-width: 6px;
    height: 6px;
    min-height: 6px;
`;

export const SocialBar: FC<any & HTMLAttributes<HTMLDivElement>> = (props: any) => (
    <SocialBarWrapper {...props}>
        <SocialLink
            href={siteConfig.socialMedia.github}
            title="Link to github!">
            <GitHubIcon />
        </SocialLink>
        <SocialSeparator />
        <SocialLink
            href={siteConfig.socialMedia.mail}
            title="Send me an mail!">
            <MailIcon />
        </SocialLink>
        <SocialSeparator />

        <ThemeSwitcherBtn title="Change theme!"/>
        <SocialSeparator />
        <NavLink to="/drawing" title="draw something!" aria-label="Drawing page"><PencilIcon /></NavLink>

    </SocialBarWrapper>
);
