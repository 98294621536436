import React, { FC, HTMLAttributes } from 'react';

export const ChevronTop: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} id="chevron-top" className="chevron chevron-top" version="1.1" viewBox="0 0 407.436 407.436" xmlns="http://www.w3.org/2000/svg">
        <polygon fill="var(--color)" points="203.718,91.567 0,294.621 21.179,315.869 203.718,133.924 386.258,315.869 407.436,294.621 " />
    </svg>
);

export const ChevronRight: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} id="chevron-right" className="chevron chevron-right" version="1.1" viewBox="0 0 407.436 407.436" xmlns="http://www.w3.org/2000/svg">
        <polygon fill="var(--color)" points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 " />
    </svg>
);

export const ChevronBottom: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} id="chevron-bottom" className="chevron chevron-bottom" version="1.1" viewBox="0 0 407.437 407.437" xmlns="http://www.w3.org/2000/svg">
        <polygon fill="var(--color)" points="386.258,91.567 203.718,273.512 21.179,91.567 0,112.815 203.718,315.87 407.437,112.815 " />
    </svg>
);

export const ChevronLeft: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} id="chevron-left" className="chevron chevron-left" version="1.1" viewBox="0 0 407.436 407.436" xmlns="http://www.w3.org/2000/svg">
        <polygon fill="var(--color)" points="315.869,21.178 294.621,0 91.566,203.718 294.621,407.436 315.869,386.258 133.924,203.718 " />
    </svg>
);

export const ThemeIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} id="theme" width="24" height="24" version="1.1" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
        <path fill="var(--color)" d="M22 41C32.4934 41 41 32.4934 41 22C41 11.5066 32.4934 3 22 3C11.5066 3 3 11.5066 3 22C3 32.4934 11.5066 41 22 41ZM7 22C7 13.7157 13.7157 7 22 7V37C13.7157 37 7 30.2843 7 22Z" />
    </svg>
);

export const TriangleUpIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <polygon fill="var(--color)" points="50 15, 100 100, 5 100" />
    </svg>
);

export const TriangleLeftIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <polygon fill="var(--color)" points="100 0, 100 100, 15 50" />
    </svg>
);

export const TriangleRightIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <polygon fill="var(--color)" points="0 0, 85 50, 0 100" />
    </svg>
);

export const TriangleDownIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} viewBox="0 0 100 30" xmlns="http://www.w3.org/2000/svg">
        <polygon fill="var(--color)" points="50 30, 0 0 , 100 0" />
    </svg>
);

export const Circle: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} height="40" width="40" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="45" />
    </svg>
);

export const Wave: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} id='wave' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 4'>
        <path fill='none' stroke='var(--color)' strokeWidth='1' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3' />
    </svg>
);

export const CloseIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} id="close" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <path d="M8.00386 9.41816C7.61333 9.02763 7.61334 8.39447 8.00386 8.00395C8.39438 7.61342 9.02755 7.61342 9.41807 8.00395L12.0057 10.5916L14.5907 8.00657C14.9813 7.61605 15.6144 7.61605 16.0049 8.00657C16.3955 8.3971 16.3955 9.03026 16.0049 9.42079L13.4199 12.0058L16.0039 14.5897C16.3944 14.9803 16.3944 15.6134 16.0039 16.0039C15.6133 16.3945 14.9802 16.3945 14.5896 16.0039L12.0057 13.42L9.42097 16.0048C9.03045 16.3953 8.39728 16.3953 8.00676 16.0048C7.61624 15.6142 7.61624 14.9811 8.00676 14.5905L10.5915 12.0058L8.00386 9.41816Z" fill="var(--color)"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM3.00683 12C3.00683 16.9668 7.03321 20.9932 12 20.9932C16.9668 20.9932 20.9932 16.9668 20.9932 12C20.9932 7.03321 16.9668 3.00683 12 3.00683C7.03321 3.00683 3.00683 7.03321 3.00683 12Z" fill="var(--color)"></path>
        </g>
    </svg>

);

export const CalendarIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g fill="var(--color)">
            <path d="M452,40h-24V0h-40v40H124V0H84v40H60C26.916,40,0,66.916,0,100v352c0,33.084,26.916,60,60,60h392
			c33.084,0,60-26.916,60-60V100C512,66.916,485.084,40,452,40z M472,452c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V188
			h432V452z M472,148H40v-48c0-11.028,8.972-20,20-20h24v40h40V80h264v40h40V80h24c11.028,0,20,8.972,20,20V148z"/>
            <rect x="76" y="230" width="40" height="40" />
            <rect x="156" y="230" width="40" height="40" />
            <rect x="236" y="230" width="40" height="40" />
            <rect x="316" y="230" width="40" height="40" />
            <rect x="396" y="230" width="40" height="40" />
            <rect x="76" y="310" width="40" height="40" />
            <rect x="156" y="310" width="40" height="40" />
            <rect x="236" y="310" width="40" height="40" />
            <rect x="316" y="310" width="40" height="40" />
            <rect x="76" y="390" width="40" height="40" />
            <rect x="156" y="390" width="40" height="40" />
            <rect x="236" y="390" width="40" height="40" />
            <rect x="316" y="390" width="40" height="40" />
            <rect x="396" y="310" width="40" height="40" />
        </g>
    </svg>
);

export const TagIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} version="1.1" viewBox="0 0 542.183 542.183" xmlns="http://www.w3.org/2000/svg">
        <g fill="var(--color)">
            <path d="M432.544,310.636c0-9.897-3.521-18.559-10.564-25.984L217.844,80.8c-7.232-7.238-16.939-13.374-29.121-18.416
			c-12.181-5.043-23.319-7.565-33.407-7.565H36.545c-9.896,0-18.464,3.619-25.694,10.848C3.616,72.9,0,81.466,0,91.365v118.771
			c0,10.088,2.519,21.219,7.564,33.404c5.046,12.185,11.187,21.792,18.417,28.837L230.12,476.799
			c7.043,7.043,15.608,10.564,25.694,10.564c9.898,0,18.562-3.521,25.984-10.564l140.186-140.47
			C429.023,329.284,432.544,320.725,432.544,310.636z M117.204,172.02c-7.139,7.138-15.752,10.709-25.841,10.709
			c-10.085,0-18.698-3.571-25.837-10.709c-7.139-7.139-10.705-15.749-10.705-25.837c0-10.089,3.566-18.702,10.705-25.837
			c7.139-7.139,15.752-10.71,25.837-10.71c10.089,0,18.702,3.571,25.841,10.71c7.135,7.135,10.707,15.749,10.707,25.837
			C127.91,156.271,124.339,164.881,117.204,172.02z"/>
            <path d="M531.612,284.655L327.473,80.804c-7.23-7.238-16.939-13.374-29.122-18.417c-12.177-5.042-23.313-7.564-33.402-7.564
			h-63.953c10.088,0,21.222,2.522,33.402,7.564c12.185,5.046,21.892,11.182,29.125,18.417l204.137,203.851
			c7.046,7.423,10.571,16.084,10.571,25.981c0,10.089-3.525,18.647-10.571,25.693L333.469,470.519
			c5.718,5.9,10.759,10.182,15.133,12.847c4.38,2.666,9.996,3.998,16.844,3.998c9.903,0,18.565-3.521,25.98-10.564l140.186-140.47
			c7.046-7.046,10.571-15.604,10.571-25.693C542.179,300.739,538.658,292.078,531.612,284.655z"/>
        </g>
    </svg>
);

export const PictureIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} version="1.1" viewBox="0 0 548.176 548.176" xmlns="http://www.w3.org/2000/svg">
        <g fill="var(--color)">
            <path d="M534.75,68.238c-8.945-8.945-19.694-13.417-32.261-13.417H45.681c-12.562,0-23.313,4.471-32.264,13.417
			C4.471,77.185,0,87.936,0,100.499v347.173c0,12.566,4.471,23.318,13.417,32.264c8.951,8.946,19.702,13.419,32.264,13.419h456.815
			c12.56,0,23.312-4.473,32.258-13.419c8.945-8.945,13.422-19.697,13.422-32.264V100.499
			C548.176,87.936,543.699,77.185,534.75,68.238z M511.623,447.672c0,2.478-0.899,4.613-2.707,6.427
			c-1.81,1.8-3.952,2.703-6.427,2.703H45.681c-2.473,0-4.615-0.903-6.423-2.703c-1.807-1.813-2.712-3.949-2.712-6.427V100.495
			c0-2.474,0.902-4.611,2.712-6.423c1.809-1.803,3.951-2.708,6.423-2.708h456.815c2.471,0,4.613,0.905,6.42,2.708
			c1.801,1.812,2.707,3.949,2.707,6.423V447.672L511.623,447.672z"/>
            <path d="M127.91,237.541c15.229,0,28.171-5.327,38.831-15.987c10.657-10.66,15.987-23.601,15.987-38.826
			c0-15.23-5.333-28.171-15.987-38.832c-10.66-10.656-23.603-15.986-38.831-15.986c-15.227,0-28.168,5.33-38.828,15.986
			c-10.656,10.66-15.986,23.601-15.986,38.832c0,15.225,5.327,28.169,15.986,38.826C99.742,232.211,112.683,237.541,127.91,237.541z
			"/>
            <polygon points="210.134,319.765 164.452,274.088 73.092,365.447 73.092,420.267 475.085,420.267 475.085,292.36 356.315,173.587" />
        </g>
    </svg>
);







export const PencilIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512">
        <g>
            <path fill="var(--color)" d="m455.1,137.9l-32.4,32.4-81-81.1 32.4-32.4c6.6-6.6 18.1-6.6 24.7,0l56.3,56.4c6.8,6.8 6.8,17.9 0,24.7zm-270.7,271l-81-81.1 209.4-209.7 81,81.1-209.4,209.7zm-99.7-42l60.6,60.7-84.4,23.8 23.8-84.5zm399.3-282.6l-56.3-56.4c-11-11-50.7-31.8-82.4,0l-285.3,285.5c-2.5,2.5-4.3,5.5-5.2,8.9l-43,153.1c-2,7.1 0.1,14.7 5.2,20 5.2,5.3 15.6,6.2 20,5.2l153-43.1c3.4-0.9 6.4-2.7 8.9-5.2l285.1-285.5c22.7-22.7 22.7-59.7 0-82.5z" />
        </g>
    </svg>
);

export const EraserIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000">
        <g>
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                <path fill="var(--color)" d="M6276.7,4994.5c-287.5-72.8-105.4,97.8-3163.1-2956C928.2-146.9,287.9-796.8,234.3-888.8C126.9-1067.1,98.2-1184,100.1-1417.9c1.9-224.3,38.3-352.7,151.4-527.2c76.7-117,2595.6-2632.1,2703-2697.2c164.9-101.6,293.3-136.1,508-138c233.9-1.9,350.8,26.8,529.1,134.2c92,53.7,740,692.1,2925.4,2879.4c3061.5,3065.3,2888.9,2881.3,2959.9,3178.4c55.6,241.5,9.6,531-122.7,745.7c-65.2,107.4-2580.3,2626.3-2697.2,2703C6836.5,5002.2,6520.2,5057.8,6276.7,4994.5z M4631.9-2961.1c-1142.5-1142.5-1100.4-1108-1248-1037.1c-95.8,44.1-2503.6,2461.4-2520.9,2528.5c-36.4,149.5-59.4,120.8,1052.4,1236.5l1039,1039L4315.6-555.2l1361.1-1361.1L4631.9-2961.1z" />
            </g>
        </g>
    </svg>
);

export const SaveIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -256 1792 1792" id="svg3001" version="1.1" width="100%" height="100%">
        <g id="g3003" transform="matrix(1,0,0,-1,129.08475,1270.2373)">
            <path fill="var(--color)" d="m 384,0 h 768 V 384 H 384 V 0 z m 896,0 h 128 v 896 q 0,14 -10,38.5 -10,24.5 -20,34.5 l -281,281 q -10,10 -34,20 -24,10 -39,10 V 864 q 0,-40 -28,-68 -28,-28 -68,-28 H 352 q -40,0 -68,28 -28,28 -28,68 v 416 H 128 V 0 h 128 v 416 q 0,40 28,68 28,28 68,28 h 832 q 40,0 68,-28 28,-28 28,-68 V 0 z M 896,928 v 320 q 0,13 -9.5,22.5 -9.5,9.5 -22.5,9.5 H 672 q -13,0 -22.5,-9.5 Q 640,1261 640,1248 V 928 q 0,-13 9.5,-22.5 Q 659,896 672,896 h 192 q 13,0 22.5,9.5 9.5,9.5 9.5,22.5 z m 640,-32 V -32 q 0,-40 -28,-68 -28,-28 -68,-28 H 96 q -40,0 -68,28 -28,28 -28,68 v 1344 q 0,40 28,68 28,28 68,28 h 928 q 40,0 88,-20 48,-20 76,-48 l 280,-280 q 28,-28 48,-76 20,-48 20,-88 z" id="path3005" />
        </g>
    </svg>
);

export const GitHubIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} version="1.1" id="github" xmlns="http://www.w3.org/2000/svg" width="438.536px" height="438.536px" viewBox="0 0 438.536 438.536" >
        <g>
            <path fill="var(--color)" d="M414.41,24.123C398.326,8.042,378.964,0,356.309,0H82.225C59.577,0,40.208,8.042,24.123,24.123
                C8.042,40.207,0,59.576,0,82.225v274.088c0,22.65,8.042,42.017,24.123,58.098c16.084,16.084,35.454,24.126,58.102,24.126h63.953
                c4.184,0,7.327-0.144,9.42-0.424c2.092-0.288,4.184-1.526,6.279-3.717c2.096-2.187,3.14-5.376,3.14-9.562
                c0-0.568-0.05-7.046-0.144-19.417c-0.097-12.375-0.144-22.176-0.144-29.41l-6.567,1.143c-4.187,0.76-9.469,1.095-15.846,0.999
                c-6.374-0.096-12.99-0.76-19.841-1.998c-6.855-1.239-13.229-4.093-19.13-8.562c-5.898-4.477-10.085-10.328-12.56-17.559
                l-2.856-6.571c-1.903-4.373-4.899-9.229-8.992-14.554c-4.093-5.332-8.232-8.949-12.419-10.852l-1.999-1.428
                c-1.331-0.951-2.568-2.098-3.711-3.429c-1.141-1.335-1.997-2.669-2.568-3.997c-0.571-1.335-0.097-2.43,1.427-3.289
                c1.524-0.855,4.281-1.279,8.28-1.279l5.708,0.855c3.808,0.76,8.516,3.042,14.134,6.851c5.614,3.806,10.229,8.754,13.846,14.843
                c4.38,7.806,9.657,13.75,15.846,17.843c6.184,4.097,12.419,6.143,18.699,6.143s11.704-0.476,16.274-1.424
                c4.565-0.954,8.848-2.385,12.847-4.288c1.713-12.751,6.377-22.559,13.988-29.41c-10.848-1.143-20.602-2.854-29.265-5.14
                c-8.658-2.286-17.605-5.995-26.835-11.136c-9.234-5.14-16.894-11.512-22.985-19.13c-6.09-7.618-11.088-17.61-14.987-29.978
                c-3.901-12.375-5.852-26.652-5.852-42.829c0-23.029,7.521-42.637,22.557-58.814c-7.044-17.32-6.379-36.732,1.997-58.242
                c5.52-1.714,13.706-0.428,24.554,3.855c10.85,4.286,18.794,7.951,23.84,10.992c5.046,3.042,9.089,5.614,12.135,7.71
                c17.705-4.949,35.976-7.423,54.818-7.423c18.841,0,37.115,2.474,54.821,7.423l10.849-6.852c7.426-4.57,16.18-8.757,26.269-12.562
                c10.088-3.806,17.795-4.854,23.127-3.14c8.562,21.51,9.328,40.922,2.279,58.241c15.036,16.179,22.559,35.786,22.559,58.815
                c0,16.18-1.951,30.505-5.852,42.969c-3.898,12.467-8.939,22.463-15.13,29.981c-6.184,7.519-13.894,13.843-23.124,18.986
                c-9.232,5.137-18.178,8.853-26.84,11.132c-8.661,2.286-18.414,4.004-29.263,5.147c9.891,8.562,14.839,22.072,14.839,40.538v68.238
                c0,3.237,0.472,5.852,1.424,7.851c0.958,1.998,2.478,3.374,4.571,4.141c2.102,0.76,3.949,1.235,5.571,1.424
                c1.622,0.191,3.949,0.287,6.995,0.287h63.953c22.648,0,42.018-8.042,58.095-24.126c16.084-16.084,24.126-35.454,24.126-58.102
                V82.225C438.533,59.576,430.491,40.204,414.41,24.123z"/>
        </g>
    </svg>
);

export const InstagramIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} id="instagram" version="1.1" viewBox="0 0 438.536 438.536" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path fill="var(--color)" d="M421.981,16.562C410.941,5.519,397.711,0,382.298,0H56.248C40.83,0,27.604,5.521,16.561,16.562   C5.52,27.6,0.001,40.828,0.001,56.243V382.29c0,15.413,5.518,28.644,16.56,39.683c11.043,11.04,24.272,16.563,39.687,16.563   h326.046c15.41,0,28.644-5.523,39.684-16.563c11.043-11.039,16.557-24.27,16.557-39.683V56.243   C438.534,40.825,433.021,27.604,421.981,16.562z M157.462,158.025c17.224-16.652,37.924-24.982,62.097-24.982   c24.36,0,45.153,8.33,62.381,24.982c17.228,16.655,25.837,36.785,25.837,60.386c0,23.598-8.609,43.729-25.837,60.379   c-17.228,16.659-38.014,24.988-62.381,24.988c-24.172,0-44.87-8.336-62.097-24.988c-17.228-16.652-25.841-36.781-25.841-60.379   C131.621,194.81,140.234,174.681,157.462,158.025z M388.865,370.589c0,4.945-1.718,9.083-5.141,12.416   c-3.433,3.33-7.519,4.996-12.282,4.996h-305.2c-4.948,0-9.091-1.666-12.419-4.996c-3.333-3.326-4.998-7.471-4.998-12.416V185.575   H89.08c-3.805,11.993-5.708,24.462-5.708,37.402c0,36.553,13.322,67.715,39.969,93.511c26.65,25.786,58.721,38.685,96.217,38.685   c24.744,0,47.583-5.903,68.527-17.703c20.937-11.807,37.486-27.839,49.676-48.112c12.183-20.272,18.274-42.4,18.274-66.38   c0-12.94-1.91-25.406-5.715-37.402h38.547v185.014H388.865z M388.865,115.626c0,5.52-1.903,10.184-5.716,13.99   c-3.805,3.809-8.466,5.711-13.989,5.711h-49.676c-5.517,0-10.185-1.903-13.99-5.711c-3.806-3.806-5.708-8.47-5.708-13.99V68.522   c0-5.33,1.902-9.945,5.708-13.848c3.806-3.901,8.474-5.854,13.99-5.854h49.676c5.523,0,10.185,1.952,13.989,5.854   c3.812,3.903,5.716,8.518,5.716,13.848V115.626z" />
        </g>
    </svg>
);

export const MailIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} id="mail" version="1.1" viewBox="0 0 640 640" width="640" height="640" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path opacity="1" fillOpacity="0" stroke="var(--color)" strokeWidth="60" strokeOpacity="1" d="M550.94 34.67C582.59 34.67 608.48 66.93 608.48 106.36C608.48 149.38 608.48 493.48 608.48 536.5C608.48 575.93 582.59 608.19 550.94 608.19C504.9 608.19 136.64 608.19 90.6 608.19C58.96 608.19 33.06 575.93 33.06 536.5C33.06 493.48 33.06 149.38 33.06 106.36C33.06 66.93 58.96 34.67 90.6 34.67C182.67 34.67 504.9 34.67 550.94 34.67Z"></path>
            <path opacity="1" fillOpacity="0" stroke="var(--color)" strokeWidth="60" strokeOpacity="1" d="M609.64 136.99L321.93 338.01L34.22 136.99"></path>
        </g>
    </svg>
);

export const CopyRightIcon: FC<HTMLAttributes<HTMLOrSVGElement>> = (props: any) => (
    <svg {...props} id="copyright" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path fill="var(--color)" d="M206.848,226.944c1.408-8.32,3.968-15.744,7.68-22.272c3.712-6.528,8.832-11.776,15.104-15.744
            c6.016-3.712,13.568-5.76,22.912-5.888c6.144,0.128,11.776,1.152,16.64,3.328c5.248,2.304,9.6,5.376,13.312,9.216
            c3.712,3.84,6.528,8.448,8.576,13.568c2.048,5.12,3.2,10.624,3.456,16.384h45.824c-0.384-12.032-2.816-23.04-7.04-33.024
            s-10.368-18.56-18.048-25.856c-7.68-7.296-16.896-12.8-27.648-16.768c-10.752-3.968-22.656-6.016-35.712-6.016
            c-16.64,0-31.104,2.816-43.392,8.576c-12.288,5.76-22.528,13.568-30.72,23.552c-8.192,9.984-14.336,21.504-18.304,34.688
            c-3.968,13.184-5.888,27.136-5.888,41.856v7.04c0,14.848,2.048,28.8,6.016,41.984c3.968,13.184,10.112,24.704,18.304,34.56
            c8.192,9.856,18.432,17.664,30.848,23.424s26.88,8.576,43.52,8.576c12.032,0,23.296-1.92,33.792-5.888
            c10.496-3.968,19.712-9.344,27.648-16.256c7.936-6.912,14.336-14.848,18.944-24.064c4.608-9.216,7.296-18.944,7.68-29.44h-45.824
            c-0.256,5.376-1.536,10.24-3.84,14.848c-2.304,4.608-5.376,8.448-9.216,11.648c-3.84,3.2-8.32,5.76-13.44,7.552
            c-4.864,1.664-9.984,2.56-15.488,2.56c-9.216-0.256-16.768-2.176-22.912-6.016c-6.4-4.096-11.52-9.344-15.232-15.872
            c-3.712-6.528-6.272-13.952-7.68-22.4s-1.92-16.64-1.92-25.216v-7.04C204.8,243.84,205.44,235.264,206.848,226.944z"/>
            <path fill="var(--color)" d="M256,0C114.56,0,0,114.56,0,256s114.56,256,256,256s256-114.56,256-256S397.44,0,256,0z M256,460.8
            c-112.896,0-204.8-91.904-204.8-204.8S143.104,51.2,256,51.2S460.8,143.104,460.8,256S368.896,460.8,256,460.8z"/>
        </g>
    </svg>
);
