// You can grab your own pattern here:
// http://www.heropatterns.com/

export const themes = {
    dark: {
        colors: {
            primary: '#F0ECE2',
            secondary: '#3CA779',
            gray: 'gray',
            color: 'white',
            background: 'rgb(59, 58, 61)', //'#474650', // 'black', // '#08202B'
            background2: 'rgb(255, 255, 255, 0.12)'
        }
    },
    light: {
        colors: {
            primary: 'black', // '#222831',
            secondary: '#6F8985',
            gray: 'gray',
            color: 'black',
            background: 'rgb(245, 245, 245)', // '#F0ECE2'
            background2: 'rgb(0, 0, 0, 0.05)',
        }
    }
}
