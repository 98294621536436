import React, { FC } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade';
import { Link } from 'gatsby';
import { Post, tabletBreakPoint } from './Common';
import { PostInfos } from './PostInfos';

const PostCover = styled.div`
    height: 150px;
    width: 100%;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    .gatsby-image-wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
    }
`;

const PostGridItemWrapper = styled(Link)`
    width: 100%;
    max-width: 600px;
    height: 100%;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    margin-bottom: var(--space-l);

    @media ${tabletBreakPoint} {
        margin-bottom: var(--space-m);
    }
`;


const SubWrapper = styled.article`
    position: relative;
`;

interface Props {
    post: Post;
}

export const PostGridItem: FC<Props> = ({ post }) => (
    <PostGridItemWrapper to={post.slug}>
        <Fade bottom distance="100px">
            <SubWrapper>

                <PostInfos post={post} onlyTitle={true} />

                {post.cover &&
                    <PostCover>
                        <Img fluid={post.cover.childImageSharp.fluid} />
                    </PostCover>
                }

            </SubWrapper>
        </Fade>
    </PostGridItemWrapper>
);
