import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import detectPassiveEvents from 'detect-passive-events';
import { EraserIcon, PencilIcon, SaveIcon, Wave, CloseIcon } from './Icons';
import { mobileBreakPoint } from './Common';
import { ThemeContext } from './ThemeContext';



const ControlsWrapper = styled.div`
    position: fixed;
    right: var(--space-l);
    bottom: var(--space-l);
    z-index: 999;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @media ${mobileBreakPoint} {
        right: var(--space-m);
        bottom: var(--space-m)
    }
`;

const ControlBtn = styled.button`
    border: 0;
    background-color: transparent;
    width: 2.2rem;
    height: 2.2rem;
    margin-left: 10px;
    cursor: pointer;

    svg {
        width: 1.8rem;
        height: 1.8rem;
        fill: var(--color);
    }
`;

interface Props {
}

interface State {
    canvas?: any;
    context?: any;
    lastX: number;
    lastY: number;
    isDrawing: boolean;
    isGomme: boolean;
    showControls: boolean;
}

export class DrawableCanvas extends React.Component<Props, State> {
    static contextType = ThemeContext;
    currentColor: string | null = null;
    lineWidth: number = 6;

    prevIsDark: boolean = true;
    downloadBtnRef: any | null = null;

    canvasStyle: any = {
        cursor: 'crosshair'
    };

    constructor(props: Props) {
        super(props);

        if (typeof window !== 'undefined') {
            // immediate = (localStorage.getItem('firstLoad') === 'false');
            this.currentColor = document.documentElement.style.getPropertyValue('--color');
        }

        this.downloadBtnRef = React.createRef();

        this.state = {
            lastX: 0,
            lastY: 0,
            isDrawing: false,
            isGomme: false,
            showControls: false
        };

        this.saveCanvasAsImage = this.saveCanvasAsImage.bind(this);
        this.resetCanvas = this.resetCanvas.bind(this);
        this.gomme = this.gomme.bind(this);
        this.handleOnMouseDown = this.handleOnMouseDown.bind(this);
        this.handleOnTouchStart = this.handleOnTouchStart.bind(this);
        this.handleOnMouseMove = this.handleOnMouseMove.bind(this);
        this.handleOnTouchMove = this.handleOnTouchMove.bind(this);
        this.handleonMouseUp = this.handleonMouseUp.bind(this);
        this.handleonMouseUp = this.handleonMouseUp.bind(this);
    }

    UNSAFE_componentWillMount() {
        const passiveEvt = detectPassiveEvents.hasSupport
            ? { passive: true }
            : false;

        if (typeof window !== 'undefined') {
            window.addEventListener('mousedown', this.handleOnMouseDown, passiveEvt);
            window.addEventListener('touchstart', this.handleOnTouchStart, passiveEvt);
            window.addEventListener('mousemove', this.handleOnMouseMove, passiveEvt);
            window.addEventListener('touchmove', this.handleOnTouchMove, passiveEvt);
            window.addEventListener('mouseup', this.handleonMouseUp, passiveEvt);
            window.addEventListener('touchend', this.handleonMouseUp, passiveEvt);
        }
    }

    componentWillUnmount() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('mousedown', this.handleOnMouseDown, false);
            window.removeEventListener('touchstart', this.handleOnTouchStart, false);
            window.removeEventListener('mousemove', this.handleOnMouseMove, false);
            window.removeEventListener('touchmove', this.handleOnTouchMove, false);
            window.removeEventListener('mouseup', this.handleonMouseUp, false);
            window.removeEventListener('touchend', this.handleonMouseUp, false);
        }

    }

    componentDidMount() {
        const canvas = ReactDOM.findDOMNode(this) as any;

        if (canvas) {
            canvas.style.position = 'fixed';
            canvas.style.top = '0';
            canvas.style.left = '0';
            canvas.style.width = '100%';
            canvas.style.height = '100%';
            canvas.width = canvas.offsetWidth;
            canvas.height = canvas.offsetHeight;
        }

        const context = canvas.getContext('2d', { willReadFrequently: true });

        this.setState({
            canvas,
            context,
        });
    }

    handleOnTouchStart(e: any) {
        const rect = this.state.canvas.getBoundingClientRect();
        this.state.context.beginPath();
        this.setState({
            lastX: e.targetTouches[0].pageX - rect.left,
            lastY: e.targetTouches[0].pageY - rect.top,
            isDrawing: true
        });
    }

    handleOnMouseDown(e: any) {
        const rect = this.state.canvas.getBoundingClientRect();
        this.state.context.beginPath();

        this.setState({
            lastX: e.clientX - rect.left,
            lastY: e.clientY - rect.top,
            isDrawing: true
        });
    }

    handleOnTouchMove(e: any) {
        const { isDrawing, lastX, lastY } = this.state;
        if (isDrawing && lastX && lastY) {
            const rect = this.state.canvas.getBoundingClientRect();
            const lastX = this.state.lastX;
            const lastY = this.state.lastY;
            const currentX = e.targetTouches[0].pageX - rect.left;
            const currentY = e.targetTouches[0].pageY - rect.top;
            this.draw(lastX, lastY, currentX, currentY);
            this.setState({
                lastX: currentX,
                lastY: currentY
            });
        }
    }

    handleOnMouseMove(e: any) {
        if (this.state.isDrawing) {
            const rect = this.state.canvas.getBoundingClientRect();
            const lastX = this.state.lastX;
            const lastY = this.state.lastY;
            const currentX = e.clientX - rect.left;
            const currentY = e.clientY - rect.top;
            this.draw(lastX, lastY, currentX, currentY);
            this.setState({
                lastX: currentX,
                lastY: currentY
            });
        }
    }

    handleonMouseUp() {
        this.setState({
            isDrawing: false
        });
    }

    draw(lX: number, lY: number, cX: number, cY: number) {
        const color = document.documentElement.style.getPropertyValue('--color');
        if (this.currentColor !== color) {
            // this.resetCanvas();
            this.currentColor = color;
        }

        const newContext = this.state.context;
        newContext.strokeStyle = this.currentColor;
        newContext.lineWidth = this.lineWidth;
        newContext.beginPath();

        this.setState({
            context: newContext,
            showControls: true
        });
        this.state.context.moveTo(lX, lY);
        this.state.context.lineTo(cX, cY);
        this.state.context.lineJoin = this.state.context.lineCap = 'round';
        this.state.context.stroke();
    }

    invertCanvasColor(isDark: boolean) {
        const { canvas, context } = this.state;

        if (this.prevIsDark === isDark) {
            return;
        } else {
            this.prevIsDark = isDark;
        }
        if (canvas && context) {
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const data = imageData.data;

            for (let i = 0; i < data.length; i += 4) {
                data[i] = isDark ? 255 : 0; // 255 - data[i];     // rouge
                data[i + 1] = isDark ? 255 : 0; // 255 - data[i + 1]; // vert
                data[i + 2] = isDark ? 255 : 0; // 255 - data[i + 2]; // bleu
            }
            context.putImageData(imageData, 0, 0);
        }
    }

    resetCanvas() {
        const { context } = this.state;

        if (context) {
            const { width, height } = this.getCanvasSize();
            context.clearRect(0, 0, width, height);
            this.setState({
                showControls: false
            });
        }
    }

    gomme() {
        const { context, isGomme } = this.state;
        if (context) {
            context.globalCompositeOperation = !isGomme ? 'destination-out' : 'destination-over';
            this.setState({
                isGomme: !isGomme,
                context,
            });
        }
    }

    saveCanvasAsImage(e: any) {
        const { canvas, context } = this.state;

        if (canvas && this.downloadBtnRef) {
            const { width, height } = this.getCanvasSize();
            const data = context.getImageData(0, 0, width, height);
            const compositeOperation = context.globalCompositeOperation;
            context.globalCompositeOperation = 'destination-over';
            context.fillStyle = this.context.dark ? 'black' : 'white';
            context.fillRect(0, 0, width, height);

            const imageData = this.state.canvas
                .toDataURL('image/png', 1)
                .replace('image/png', 'image/octet-stream');

            context.clearRect(0, 0, width, height);
            context.putImageData(data, 0, 0);
            context.globalCompositeOperation = compositeOperation;

            const a = document.createElement('a')
            a.href = imageData
            a.download = `weird-${this.uuidv4()}.png`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        }
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = Math.random() * 16 | 0;
            const v = (c == 'x') ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    getCanvasSize(): { width: number; height: number } {
        const { context } = this.state;
        return {
            width: context.canvas.width,
            height: context.canvas.height
        };
    }

    render() {
        const { showControls, isGomme } = this.state;
        this.invertCanvasColor(this.context.dark);

        return (
            <>
                <canvas style={this.canvasStyle}></canvas>
                {showControls &&
                    <ControlsWrapper>
                        <ControlBtn onClick={this.gomme} title={isGomme ? 'Active eraser!' : 'Active pencil!'}>{isGomme ? (<PencilIcon />) : (<EraserIcon />)}</ControlBtn>
                        <ControlBtn onClick={this.resetCanvas} title="Erase all!"><CloseIcon /></ControlBtn>
                        <ControlBtn ref={this.downloadBtnRef} onClick={this.saveCanvasAsImage} title="Save!"><SaveIcon /></ControlBtn>
                    </ControlsWrapper>
                }
            </>
        );
    }
}
