import React, { Component } from 'react';
import styled from 'styled-components';
import { TriangleUpIcon } from './Icons';
import getEventOptions from '../utils/getEventOptions';

const ScrollButtonWrapper = styled.button`
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    border: none;
    width: 60px;
    height: 60px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 996;
    transition: all 0.2s;

    .triangle {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 60px;
        stroke-linecap: unset;
        transition: all 0.3s;

        polygon {
            fill: var(--secondary) !important;
        }
    }
`;

interface Props {
}

interface State {
    show: boolean;
}

export class ScrollButton extends Component<Props, State> {

    Animation: any;

    constructor(props: Props) {
        super(props)

        this.state = {
            show: false
        };

        this.Animation = {
            StartPosition: 0,
            CurrentAnimationTime: 0,
            StartTime: null,
            AnimationFrame: null,
        };

        this.HandleScroll = this.HandleScroll.bind(this);
        this.StopScrollingFrame = this.StopScrollingFrame.bind(this);
        this.ScrollingFrame = this.ScrollingFrame.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', this.HandleScroll);
            window.addEventListener('wheel', this.StopScrollingFrame, getEventOptions()); // Stop animation if user mouse wheels during animation.
            window.addEventListener('touchstart', this.StopScrollingFrame, getEventOptions());
        }

    }

    componentWillUnmount() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('scroll', this.HandleScroll, false);
            window.removeEventListener('wheel', this.StopScrollingFrame, false);
            window.removeEventListener('touchstart', this.StopScrollingFrame, false);
        }
    }

    HandleScroll() {
        const height = 200; // window.screen.height;

        if (window.pageYOffset > height) {
            this.setState({ show: true });
        } else {
            this.setState({ show: false });
        }
    }

    handleClick(_event: any) {
        this.StopScrollingFrame();// Stoping all AnimationFrames
        this.Animation.StartPosition = window.pageYOffset;// current scroll position
        this.Animation.CurrentAnimationTime = 0;
        this.Animation.StartTime = null;
        // Start the scrolling animation.
        this.Animation.AnimationFrame = window.requestAnimationFrame(this.ScrollingFrame);
    }

    linear(t: number, b: number, _c: number, d: number) {
        const c = _c - b;
        return c * t / d + b;
    }

    ScrollingFrame() {
        const StopPosition = 0
        const AnimationDuration = 200;

        const timestamp = Math.floor(Date.now());
        // If StartTime has not been assigned a value, assign it the start timestamp.
        if (!this.Animation.StartTime) {
            this.Animation.StartTime = timestamp;
        }

        // set CurrentAnimationTime every iteration of ScrollingFrame()
        this.Animation.CurrentAnimationTime = timestamp - this.Animation.StartTime;
        // if we hit the StopPosition, StopScrollingFrame()
        if (window.pageYOffset <= StopPosition) {
            this.StopScrollingFrame();
        } else {
            // Otherwise continue ScrollingFrame to the StopPosition.
            // Does not support horizontal ScrollingFrame.
            // Let TweenFunctions handle the math to give us a new position based on AnimationDuration and EasingType type
            let YPos = this.linear(
                this.Animation.CurrentAnimationTime,
                this.Animation.StartPosition,
                StopPosition,
                AnimationDuration
            );
            if (YPos <= StopPosition) {
                YPos = StopPosition
            }
            window.scrollTo(0, YPos);
            // Request another frame to be painted
            this.Animation.AnimationFrame = window.requestAnimationFrame(this.ScrollingFrame);
        }
    }

    StopScrollingFrame() {
        // Stop the Animation Frames.
        window.cancelAnimationFrame(this.Animation.AnimationFrame);
    }

    render() {
        const { show } = this.state;
        const styles = show
            ? { bottom: '0' }
            : { bottom: '-70px' };

        return (
            <ScrollButtonWrapper style={styles} onClick={this.handleClick} aria-label="Scroll to top of the page">
                <TriangleUpIcon className="triangle" />
            </ScrollButtonWrapper>
        );
    }
}
