import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { FlexSeparator, LayoutType, tabletBreakPoint } from './Common';
import { NavBar } from './NavBar';
import { NavLogo } from './NavLogo';
import { useScrollPosition } from '../utils/useScrollPosition';

const HeaderWrapper = styled.header`
    position: fixed;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--background);

    &.homeHeader {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);

        .navLogo {
            height: 100%; // 150px;
            max-width: 600px;
            max-height: 200px;
            min-height: 150px;
            padding: var(--space-l);
            margin: 0 auto;
        }

        .navBar {
            position: absolute;
            bottom: var(--space-l);
            width: 100%;
        }
    }

    &.pageHeader,
    &.postHeader {
        .navLogo {
            height: var(--space-xl);
            min-height: var(--space-xl);
        }

        .navBar {
            position: absolute;
            top: var(--space-xl);
            transition: all ease .2s;
            transform: translateY(var(--space-xl));
            background-color: var(--background);
        }

        &.hiddenNav {
            .navBar {
                position: absolute;
                transform: translateY(-100px) !important;
            }
        }
    }
`;

interface Props {
    layoutType?: LayoutType;
    hideNavBar?: boolean;
}

export const Header: FC<Props & HTMLAttributes<HTMLDivElement>> = (props: any) => {
    const scrollPos = useScrollPosition();

    const className = classNames(
        'commonHeader', {
        'homeHeader': props.layoutType === LayoutType.HOME,
        'pageHeader': props.layoutType === LayoutType.PAGE,
        'postHeader': props.layoutType === LayoutType.POST,
        'hiddenNav': scrollPos.dir === 'DOWN'
    });

    return (
        <HeaderWrapper {...props} className={className}>
            <NavLogo className="navLogo" layouttype={props.layoutType}></NavLogo>

            {!props.hideNavBar &&
                <>
                    { props.layoutType !== LayoutType.HOME && <FlexSeparator />}
                    <NavBar className="navBar"></NavBar>
                </>
            }
        </HeaderWrapper>
    );
}
