import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Text, tabletBreakPoint } from './Common';
import { SocialBar } from './SocialBar';
import { CopyRightIcon } from './Icons';

const FooterWrapper = styled.footer`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: var(--space-l);
    position: relative;
    bottom: 0;
    width: 100%;
    justify-content: flex-end;

    ${Text} {
        svg {
            height: 12px;
            width: 12px;
        }
    }

    @media ${tabletBreakPoint} {
        flex-direction: column;
        margin-bottom: calc(var(--space-l) + var(--space-s));
        text-align: center;
        background-color: transparent;
        position: relative;
        width: 100%;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    margin-right: 10px;

    ${Text} {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    @media ${tabletBreakPoint} {
        flex-direction: row;

        ${Text} {
            margin: 0 5px 5px 0;
            justify-content: flex-end;
        }
    }
`;

export const Footer: FC<any & HTMLAttributes<HTMLDivElement>> = (props: any) => (
    <FooterWrapper {...props} >
        <TextWrapper>
            <Text
                fontSize="var(--font-size-s)"
                lineHeight="var(--font-size-s)"
                whiteSpace="normal"
                style={{ textTransform: 'uppercase' }}>
                <CopyRightIcon />&nbsp; {new Date().getFullYear()}
            </Text>
            <Text
                fontSize="var(--font-size-s)"
                lineHeight="var(--font-size-s)"
                whiteSpace="normal"
                style={{ textTransform: 'uppercase' }}>
                radiium
            </Text>

        </TextWrapper>

        <SocialBar />

    </FooterWrapper>
);
