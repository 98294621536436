import React, { FC } from 'react';
import styled from 'styled-components';
import { TriangleLeftIcon, TriangleRightIcon } from './Icons';
import { NavLink } from './Common';

interface Props {
    next: any;
    prev: any;
}

const GridPaginationWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: calc(var(--space-l) * 2);
    margin-bottom: calc(var(--space-l) * 2);
    width: 100%;

    ${NavLink} {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;

        &.prevPost {
            justify-content: flex-start;
            text-align: left;
            flex: 1 1 auto;

            .triangle {
                margin-right: var(--space-xxs);
            }

        }

        &.nextPost {
            justify-content: flex-end;
            text-align: right;
            flex: 1 1 auto;

            .triangle {
                margin-left: var(--space-xxs);
            }
        }

        .triangle {
            height: var(--space-l);
            width: var(--space-l);
            transition: transform linear .2s;

            polygon {
                fill: var(--color);
            }
        }

        &:hover {
            &.prevPost {
                .triangle{
                    transform: translateX(-4px);
                }
            }
            &.nextPost {
                .triangle{
                    transform: translateX(4px);
                }
            }
        }
    }
`;

export const PostPagination: FC<Props> = ({ next, prev }) => (
    <GridPaginationWrapper>
        {prev && (
            <NavLink className="prevPost"
                to={prev.fields.slug}
                style={{ marginRight: '0' }}>
                <TriangleLeftIcon className="triangle"/>
                PREV
            </NavLink>
        )}

        {next && (
            <NavLink className="nextPost"
                to={next.fields.slug}
                style={{ marginRight: '0' }}>
                NEXT
                <TriangleRightIcon className="triangle"/>
            </NavLink>
        )}
    </GridPaginationWrapper>
);
