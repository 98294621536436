import React from 'react';
import styled from 'styled-components'
import { Link } from 'gatsby';
import classNames from 'classnames';

/**
 *
 * COMPONENTS
 *
 */

export const mobileBreakPoint = '(max-width: 490px)';
export const tabletBreakPoint = '(max-width: 770px)';
export const desktopBreakPoint = '(max-width: 1100px)';

export const Text = styled.div<any>`
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${props => props.color || 'var(--color)'};
    white-space: ${props => props.whiteSpace || 'nowrap'};
    font-size: ${props => props.fontSize || '1rem'};
    text-align: ${props => props.textAlign || 'inherit'};
    font-weight: ${props => props.fontWeight || 'normal'};
    font-style: ${props => props.fontStyle || 'normal'};
    line-height: ${props => props.lineHeight || 'normal'};

    @media ${mobileBreakPoint} {
        font-size: ${props => props.fontSizeMobile || ''};
    }
`;

export const FlexSeparator = styled.div<any>`
    flex: 1 1 auto;
`;

export const NavLink = styled(({ className, to, children, ...rest }) => {
    const activeClassName = 'nav-active';
    return (<Link
        {...rest}
        className={className}
        to={to}
        getProps={({ isPartiallyCurrent }) =>
            (isPartiallyCurrent
                ? { className: classNames(className, activeClassName) }
                : null) as any
        }>
        {children}
    </Link>);
})`
    // padding: 0 10px;
    margin-right: 15px;
    position: relative;
    color: var(--color);
    font-size: var(--font-size-l);
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    svg {
        height: 1.6rem;
        width: 1.6rem;
    }

    :hover {}
    &.nav-active {}

    @media ${mobileBreakPoint} {
        // font-size: var(--font-size-m);

        svg {
            height: 1.45rem;
            width: 1.45rem;
        }
    }
`;

export const DateText = styled.time<any>`
    color: ${props => props.color || 'var(--color)'};
`;

/**
 *
 * MODELS
 *
 */

const get = (object: any, path: string | Array<string>, value?: any) => {
    if (!path) return undefined;
    const pathArray = Array.isArray(path)
        ? path
        : path.split(/[,[\].]/g).filter(Boolean);

    return pathArray.reduce((prevObj, key) => prevObj && prevObj[key], object) || value
}

export const queryPostToPost = (queryPost: any): Post => {
    return {
        slug: get(queryPost, 'node.fields.slug', ''),

        title: get(queryPost, 'node.frontmatter.title', ''),
        description: get(queryPost, 'node.frontmatter.description', ''),
        date: get(queryPost, 'node.frontmatter.date', undefined),
        displayDate: get(queryPost, 'node.frontmatter.displayDate', undefined),
        category: get(queryPost, 'node.frontmatter.category', ''),
        tags: get(queryPost, 'node.frontmatter.tags', []),

        cover: get(queryPost, 'node.frontmatter.cover', undefined),
        images: get(queryPost, 'node.frontmatter.images', []),
        imagesCount: get(queryPost, 'node.frontmatter.images', []).length,
    }
};


export interface Post {
    slug: string;
    title: string;
    description: string;
    date?: string;
    displayDate?: string;
    category?: string;
    tags?: string[];
    cover?: any;
    images?: PostImage[];
    imagesCount?: number;
}

export interface PostImage {
    title: string;
    description: string;
    dimension: string[];
    date: string;
    src: string | any;
}

export interface ChildImageSharp {
    fluid: Fluid;
}

export interface Fluid {
    aspectRatio: string;
    base64: string;
    sizes: string;
    src: string;
    srcSet: string;
    srcSetWebp: string;
    srcWebp: string;
}

export enum LayoutType {
    HOME = 'HOME',
    PAGE = 'PAGE',
    POST = 'POST'
}
