import React, { FC } from 'react';
import styled from 'styled-components';
import { ThemeProvider, ScrollButton } from '../components/index';

import '../styles/styles.scss';

const PageWrapper = styled.main`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    // min-height: 100vh;
    // height: calc(var(--vh, 1vh) * 100);
    margin: 0 auto;
    position: relative;
`;

export const LayoutBase: FC = ({ children }) => {
    return (
        <ThemeProvider>
            <PageWrapper>

                <ScrollButton />
                {children}

            </PageWrapper>
        </ThemeProvider>
    );
};
