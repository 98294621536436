import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { NavLink, mobileBreakPoint } from './Common';
import { ThemeSwitcherBtn } from './ThemeSwitcherBtn';
import { PencilIcon } from './Icons';
// import { ThemeSwitcherBtn } from './ThemeSwitcherBtn';
// import { PencilIcon } from './Icons';

const NavBarWrapper = styled(animated.nav)`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: var(--space-xxs);

    @media ${mobileBreakPoint} {
        // justify-content: space-between;
    }
`;

export const NavBar: FC<any & HTMLAttributes<HTMLDivElement>> = (props: any) => {
    const animProps = useSpring({
        // config: config.slow,
        // delay: 500,
        // from: { opacity: 0, transform: 'translateY(-50px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
    });

    return (
        <NavBarWrapper {...props} style={animProps}>
            <NavLink to="/works" aria-label="Works page">Works</NavLink>
            <NavLink to="/about" aria-label="About page">About</NavLink>
        </NavBarWrapper>
    );
}
