export default {
    url: 'https://radiium.space',
    pathPrefix: '/',

    themeColor: '#6F8985',
    backgroundColor: '#F0ECE2',

    title: 'Radiium',
    titleAlt: 'Radiium | Visual art',
    titleShort: 'Radiium',
    description: 'Visual art and others experiences',
    headline: 'Visual art',
    author: 'Radiium',

    location: 'France',
    language: 'fr',
    locale: 'fr_FR',

    favicon: '/logos/logo.png',
    icon: '/logos/logo.png',
    logo: '/logos/logo.png',
    staticFolder: 'static',
    display: 'standalone',

    socialMedia: {
        github: 'https://www.github.com/radiium/',
        mail: 'mailto:remi.poncet@radiium.space?subject=Hello'
    }
}
