import { useState, useEffect } from 'react';

interface ScrollPosition {
    x: number;
    y: number;
}

enum ScrollDirection {
    UP = 'UP',
    DOWN = 'DOWN'
}

const isBrowser = typeof window !== `undefined`;

function getScrollPosition(): ScrollPosition {
    return isBrowser ? { x: window.pageXOffset, y: window.pageYOffset } : { x: 0, y: 0 };
}

export function useScrollPosition(): any {
    const [position, setScrollPosition] = useState<ScrollPosition>(getScrollPosition());
    const [direction, setScrolldirection] = useState(ScrollDirection.UP);
    let prev = getScrollPosition()

    useEffect(() => {
        let requestRunning: number | null = null;
        function handleScroll() {
            if (isBrowser && requestRunning === null) {
                requestRunning = window.requestAnimationFrame(() => {
                    const next =  getScrollPosition()
                    let direction = prev.y < next.y ? ScrollDirection.DOWN : ScrollDirection.UP
                    setScrollPosition(next);
                    setScrolldirection(direction);
                    prev = next;
                    requestRunning = null;
                });
            }
        }

        if (isBrowser) {
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return { ...position, dir: direction };
}
