import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import config from '../../config/site'

interface Props {
    postNode?: any;
    postPath?: string;
}

export const SEO: FC<Props> = ({ postNode, postPath }) => {
    const data = useStaticQuery(querySEO)

    let title: string;
    let description: string;
    let image: string;

    const bt = data.site.buildTime
    const realPrefix = config.pathPrefix === '/' ? '' : config.pathPrefix
    const homeURL = `${config.url}${realPrefix}`
    const URL = `${homeURL}${postPath || ''}`

    if (!!postNode) {
        title = `${config.title} | ${postNode.title}`;
        description = `${config.description} - ${postNode.title} - ${postNode.tags.join(', ')}`;
        image = `${homeURL}${postNode.images[0]
            ? postNode.images[0].src.childImageSharp.desktop.src
            : config.logo}`;

    } else {
        title = `${config.titleAlt}`;
        description = `${config.description}`;
        image = `${homeURL}${config.logo}`;
    }

    // schema.org in JSONLD format
    // https://developers.google.com/search/docs/guides/intro-structured-data
    // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

    const schemaOrgWebPage = {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        url: URL,
        headline: config.headline,
        inLanguage: 'en', // config.language,
        mainEntityOfPage: URL,
        description: config.description,
        name: config.title,
        author: {
            '@type': 'Person',
            name: config.author,
        },
        copyrightHolder: {
            '@type': 'Person',
            name: config.author,
        },
        copyrightYear: '2018',
        creator: {
            '@type': 'Person',
            name: config.author,
        },
        publisher: {
            '@type': 'Person',
            name: config.author,
        },
        datePublished: '2019-01-07T10:30:00+01:00',
        dateModified: bt,
        image: {
            '@type': 'ImageObject',
            url: image,
        },
    }

    // Initial breadcrumb list

    const itemListElement = [
        {
            '@type': 'ListItem',
            item: {
                '@id': homeURL,
                name: 'Homepage',
            },
            position: 1,
        },
    ]

    let schemaArticle: any;

    if (!!postNode) {
        schemaArticle = {
            '@context': 'http://schema.org',
            '@type': 'Article',
            author: {
                '@type': 'Person',
                name: config.author,
            },
            copyrightHolder: {
                '@type': 'Person',
                name: config.author,
            },
            // copyrightYear: postNode.parent.birthtime,
            creator: {
                '@type': 'Person',
                name: config.author,
            },
            publisher: {
                '@type': 'Organization',
                name: config.author,
                logo: {
                    '@type': 'ImageObject',
                    url: `${homeURL}${config.logo}`,
                },
            },
            // datePublished: postNode.parent.birthtime,
            // dateModified: postNode.parent.mtime,
            description,
            headline: title,
            inLanguage: 'en',
            url: URL,
            name: title,
            image: {
                '@type': 'ImageObject',
                url: image,
            },
            mainEntityOfPage: URL,
        }
        // Push current blogpost into breadcrumb list
        itemListElement.push({
            '@type': 'ListItem',
            item: {
                '@id': URL,
                name: title,
            },
            position: 2,
        })
    }

    const breadcrumb = {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        description: 'Breadcrumbs list',
        name: 'Breadcrumbs',
        itemListElement,
    }

    return (
        <Helmet>
            <html lang={config.language} />
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="image" content={image} />

            <meta http-equiv="X-UA-Compatible" content="IE=9" />

            <meta property="og:url" content={URL} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:image:alt" content={description} />
            <meta property="og:locale" content={config.locale} />
            <meta property="og:site_name" content={config.title} />
            <meta property="og:type" content={!!postNode ? 'article' : 'siteweb'} />

            {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
            <script type="application/ld+json">
                {!!postNode
                    ? JSON.stringify(schemaArticle)
                    : JSON.stringify(schemaOrgWebPage)}
            </script>
            <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
        </Helmet>
    )
}

const querySEO = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
    }
  }
`
