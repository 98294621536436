import React, { FC, HTMLAttributes, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Gradient } from './Gradient';
import { LayoutType } from './Common';

const NavLogoWrapper = styled(Link) <any>`
    width: 100%;
    transition: all ease-in-out 200ms;
    transition-delay: 2000ms;
    z-index: 1;
`;

interface Props {
    layouttype?: LayoutType;
}

export const NavLogo: FC<Props & HTMLAttributes<HTMLDivElement>> = (props) => (
    <NavLogoWrapper {...props} to="/" aria-label="Home page">
        <Gradient layouttype={props.layouttype} />
    </NavLogoWrapper>
);
