import React, { FC } from 'react';
import styled from 'styled-components';
import { LayoutBase } from './LayoutBase';
import {
    Header,
    Footer,
    tabletBreakPoint,
    LayoutType,
    SEO } from '../components';

const MainPage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--space-l);
    margin-top: calc(var(--space-xl) * 2);

    @media ${tabletBreakPoint} {
        width: 100vw;
        padding: var(--space-s);
    }
`;

export const LayoutPage: FC = ({ children }) => (
    <LayoutBase>
        <SEO/>
        <Header layoutType={LayoutType.PAGE}/>
        <MainPage>
            {children}
            <Footer></Footer>
        </MainPage>
    </LayoutBase>
);
